/**
 * A list of all custom error codes used by Stockpicks.
 * These are used when presenting an error to the user,
 * and also send to our logs to make it easy to debug.
 */
export const Events = {
  PageLoaded: {
    name: 'Page loaded',
  },
  SearchingForQuote: {
    name: 'Searching for quote',
  },
  SearchingForQuoteOnCompanyNameFailed: {
    name: 'Could not find quote when searching on company name',
  },
  SearchingForQuoteOnISINFailed: {
    name: 'Could not find quote when searching on isin',
  },
  NoHistoricalDataForInstrumentForParticularRange: {
    name: 'No historical data for Instrument for particular range',
  },
  DeriveCountryCodeFromQuotePayloadFailed: {
    name: 'Could not derive country code from Yahoo Finance quote endpoint',
  },
} as const;

export type Event = keyof typeof Events;
