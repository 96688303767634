import * as Sentry from '@sentry/nextjs';
import pino from 'pino';
import { logflarePinoVercel } from 'pino-logflare';
import { ErrorCode } from './error-codes';

type UserWithPropertiesToTrack = {
  id: string;
  username?: string;
  name?: string;
  email?: string;
};

interface LogArgs {
  message: string;
  extra?: Record<string, any>;
}
interface ErrorLogArgs {
  code: ErrorCode;
  extra?: Record<string, any>;
}

let currentLoggedInUser: UserWithPropertiesToTrack | null = null;

// create pino-logflare console stream for serverless functions and send function for browser logs
const { stream, send } = logflarePinoVercel({
  apiKey: process.env.LOGFLARE_API_TOKEN,
  sourceToken: process.env.LOGFLARE_SOURCE_TOKEN,
});

// create pino loggger, in production we send logs to logflare using `pino-logflare`
const pinoLogger =
  process.env.NODE_ENV === 'production'
    ? pino(
        {
          browser: {
            transmit: {
              level: 'debug', // Only `debug` or higher levels will be sent, in other words; `trace` will not get sent, see https://github.com/pinojs/pino/blob/master/docs/api.md#loggerlevels-object
              send: send,
            },
          },
          base: {
            env: process.env.NODE_ENV || 'ENV not set',
            revision: process.env.VERCEL_GITHUB_COMMIT_SHA,
          },
        },
        stream,
      )
    : pino({
        level: process.env.LOGGER_LEVEL_DEVELOPMENT || 'warn',
        transport: {
          target: 'pino-pretty',
        },
      });

const logToSentry = async (level: Sentry.SeverityLevel, args: ErrorLogArgs) => {
  if (typeof window !== 'undefined') {
    throw new Error(
      'Only use logger in node.js! @sentry/react is used on the frontend.',
    );
  }

  Sentry.captureMessage(args.code, {
    level,
    extra: typeof args.extra === 'string' ? { extra: args.extra } : args.extra,
  });
  await Sentry.flush(2000);
};

const logToPino = (level: pino.Level, args: LogArgs | ErrorLogArgs) => {
  if ('message' in args) {
    // if we have message, its not an error
    const { message, ...otherArgs } = args;
    pinoLogger[level]({ ...otherArgs, user: currentLoggedInUser }, message);
  } else {
    // if we have code, its an error
    const { code, ...otherArgs } = args;
    pinoLogger[level]({ ...otherArgs, user: currentLoggedInUser }, code);
  }
};

/**
 * Node.js logger. Only use in backend.
 */
export const logger = {
  debug: async (args: LogArgs) => {
    logToPino('debug', args);
  },
  info: async (args: LogArgs) => {
    logToPino('info', args);
  },
  warn: async (args: LogArgs) => {
    logToPino('warn', args);
  },
  error: async (args: ErrorLogArgs) => {
    logToPino('error', args);
    await logToSentry('error', args);
  },
  fatal: async (args: ErrorLogArgs) => {
    logToPino('fatal', args);
    await logToSentry('fatal', args);
  },
  setUser: async (user: UserWithPropertiesToTrack) => {
    currentLoggedInUser = user;
    Sentry.setUser({
      username: user?.username,
      email: user?.email,
      id: user?.id,
    });
  },
} as const;
