import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import '../styles/globals.css';
import { withApollo } from '../lib/apollo';
import { logger, Events } from '../lib/logger';

const ErrorComponent: FC<any> = ({ error, componentStack }) => {
  console.error(componentStack);
  // @ts-ignore
  return <Error statusCode="Error" title={<code>{error?.message}</code>} />;
};

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  const handleRouteChange = url => {
    logger.info({
      message: Events.PageLoaded.name,
      extra: { url: url },
    });
  };

  useEffect(() => {
    // Call handleRouteChange whenever route changes
    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorComponent}>
      <Component {...pageProps} />
    </Sentry.ErrorBoundary>
  );
};

export default withApollo(MyApp);
