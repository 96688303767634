/**
 * A list of all custom error codes used by Stockpicks.
 * These are used when presenting an error to the user,
 * and also send to our logs to make it easy to debug.
 */
export const ErrorCodes = {
  QuoteNotFound: {
    name: 'QuoteNotFound',
    code: 400001,
  },
  HistoricalDataNotFound: {
    name: 'HistoricalDataNotFound',
    code: 400002,
  },
  GetInstrumentPercentageHelperError: {
    name: 'GetInstrumentPercentageHelperError',
    code: 400003,
  },
  MismatchOfPropertiesWhenUnifyingInstruments: {
    name: 'Mismatch of properties when unifying instruments',
  },
} as const;

export type ErrorCode = keyof typeof ErrorCodes;
